import React from "react";
import "../index.css";
import { useHistory } from "react-router";

const Home = () => {
  let history = useHistory();

  /*} const onVolunteer = () => {
     history.push("/register-volunteer");
   };
 
   const onAPL = () => {
     history.push("/register-apl");
   };*/

  /* const onAFL = () => {
    history.push("/register-afl");
  };*/

  const onEvents = () => {
    history.push("/events");
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="d-flex flex-wrap justify-content-center align-middle">
            <div className=" button-container">
              <div
                className="glass-btn blue-btn"
                style={{
                  color: "white",
                  fontSize: "3.5rem",
                  fontFamily: "Lobster",
                }}
              >
                <a href="https://www.acharyahabba.com">Habba 2025</a>
              </div>
            </div>

            {/*<div className=" button-container">
              <div className="glass-btn blue-btn" onClick={onVolunteer}>
                <img src="/vol.svg" className="image" alt="facebook" />
              </div>
            </div>

            <div className=" button-container">
              <div className="glass-btn blue-btn" onClick={onAPL}>
                <img src="/apl.svg" className="image2" alt="facebook" />
              </div>
            </div> */}

            {/* <div className=" button-container">
              <div
                className="glass-btn blue-btn"
                onClick={onAFL}
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontFamily: "cursive",
                }}
              >
                <img
                  src="/afl.png"
                  alt="afl"
                  style={{
                    width: "200px",
                    position: "relative",
                    top: "-40px",
                  }}
                />
                Acharya Football League
              </div>
            </div>*/}

            <div className=" button-container">
              <div
                className="glass-btn blue-btn"
                onClick={onEvents}
                style={{
                  color: "white",
                  fontSize: "2.5rem",
                  fontFamily: "Lobster",
                }}
              >
                Event Registration
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
