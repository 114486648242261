import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import axios from "axios";

import Home from "./Pages/Home";
import Mainpage from "./Pages/Mainpage";
import VolunteerMain from "./Pages/VolunteerMain";
import EventsMain from "./Pages/EventsMain";
import AFL from "./Pages/AFL";

function App() {
  React.useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL);
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/home" component={Mainpage} />
        <Route exact path="/register-apl" component={Mainpage} />
        <Route exact path="/register-afl" component={AFL} />
        <Route exact path="/events" component={EventsMain} />
        <Route exact path="/register-volunteer" component={VolunteerMain} />
        <Route exact path="/" component={Home} />
        <Route exact path="/website" component={"https://register.acharyahabba.com/events"} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}

export default App;
